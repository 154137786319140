$(function() {

    if ($(document).width() > 768) {

        $('.scroller').customScrollbar({
            updateOnWindowResize: true
        });
    }
});

$(document).on('click', '#mobile-menu', function() {
$(this).hide();
    $('#content').fadeOut(200, function() {

        $('#page').css('padding-top', '0');
        $('#menu').fadeIn(200);
    });
});